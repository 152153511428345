// material-ui
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third party
import { Formik } from 'formik';
import * as Yup from 'yup';

// project imports
import { useAuth } from 'hooks/AuthContext';
import { useNavigate } from 'react-router';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets


// ===========================|| FIREBASE - REGISTER ||=========================== //

const OnboardForm = ({ ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { currentUser, addUserInfo } = useAuth();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    return (
        <>
            <Formik
                initialValues={{
                    email: currentUser.email,
                    fname: '',
                    lname: '',
                    street: '',
                    city: '',
                    postalCode: '',
                    phoneNumber: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    fname: Yup.string().max(255).required('First name is required').matches(/^[A-Za-z]+$/i, "First name only contains alphabets."),
                    lname: Yup.string().max(255).required('Last name is required').matches(/^[A-Za-z]+$/i, "Last name only contains alphabets."),
                    street: Yup.string().max(255).required('Address is required'),
                    city: Yup.string().max(255).required('City is required'),
                    postalCode: Yup.string().max(7).required('Postal code is required').matches(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/, "Please enter the value in the format: A1A 1A1"),
                    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(12, "At most 12 digit phone number accepts."),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        addUserInfo(values.email, values.fname, values.lname, values.street, values.city, values.postalCode, values.phoneNumber, currentUser);
                    } catch (error) {
                        console.log(error);
                    }
                    setSubmitting(false);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Grid container columnSpacing={2} >
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={Boolean(touched.fname && errors.fname)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-fname">First Name</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-fname"
                                        type="text"
                                        value={values.fname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="First Name"
                                        name="fname"
                                    />
                                    {touched.fname && errors.fname && <FormHelperText>{errors.fname}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={Boolean(touched.lname && errors.lname)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-lname">Last Name</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-lname"
                                        type="text"
                                        value={values.lname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Last Name"
                                        name="lname"
                                    />
                                    {touched.lname && errors.lname && <FormHelperText>{errors.lname}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={Boolean(touched.street && errors.street)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-street">Address</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-street"
                                        type="text"
                                        value={values.street}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Address"
                                        name="street"
                                    />
                                    {touched.street && errors.street && <FormHelperText>{errors.street}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={Boolean(touched.city && errors.city)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-city">City</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-city"
                                        type="text"
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="City"
                                        name="city"
                                    />
                                    {touched.city && errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={Boolean(touched.postalCode && errors.postalCode)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-postalCode">Postal Code</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-postalCode"
                                        type="text"
                                        value={values.postalCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Postal Code"
                                        name="postalCode"
                                    />
                                    {touched.postalCode && errors.postalCode && <FormHelperText>{errors.postalCode}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={Boolean(touched.phoneNumber && errors.phoneNumber)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-phoneNumber">Phone Number</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-phoneNumber"
                                        type="text"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Phone Number"
                                        name="phoneNumber"
                                    />
                                    {touched.phoneNumber && errors.phoneNumber && <FormHelperText>{errors.phoneNumber}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-email-register">Email Address</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-register"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Next
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default OnboardForm;