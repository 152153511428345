// assets
import { IconDashboard, IconCalendarEvent, IconShoppingCartPlus, IconClipboardData } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconCalendarEvent, IconShoppingCartPlus, IconClipboardData };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'milton-lessons',
        //     title: 'Milton Registration',
        //     type: 'item',
        //     url: '/milton-lessons',
        //     icon: icons.IconCalendarEvent,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'booking-page',
        //     title: 'Book a Lesson',
        //     type: 'item',
        //     url: '/booking-lessons',
        //     icon: icons.IconCalendarEvent,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'purchase-page',
        //     title: 'Add Lessons',
        //     type: 'item',
        //     url: '/purchase-lessons',
        //     icon: icons.IconShoppingCartPlus,
        //     breadcrumbs: true
        // },
        {
            id: 'lesson-registration',
            title: 'Lesson Registration',
            type: 'item',
            url: '/lesson-registration',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false,
        },
        // {
        //     id: 'swimmer-progress-page',
        //     title: 'Swimmer Progress',
        //     type: 'item',
        //     url: '/swimmer-progress',
        //     icon: icons.IconShoppingCartPlus,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'account-activity',
        //     title: 'Account Activity',
        //     type: 'item',
        //     url: '/account-activity',
        //     icon: icons.IconClipboardData,
        // }
    ]
};

export default dashboard;
