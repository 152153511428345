import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Onboarding from 'views/onboarding';

// purchase lessons page routing
const PurchaseLessons = Loadable(lazy(() => import('views/purchase-lessons')));


const NewClientRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/onboarding',
            element: <Onboarding />
        },
    ]
};

export default NewClientRoutes;