import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { AuthProvider } from 'hooks/AuthContext';
import { createClient, Provider, defaultExchanges, cacheExchange, fetchExchange, subscriptionExchange, debugExchange } from 'urql';
import { yogaExchange } from '@graphql-yoga/urql-exchange'
import { BookingProvider } from 'hooks/BookingContext';
import { SupabaseProvider } from 'hooks/SupabaseContext';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const client = createClient({
        url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        exchanges: [
            ...defaultExchanges,
            fetchExchange,
            cacheExchange,
            subscriptionExchange({
                forwardSubscription(operation) {
                    const url = new URL(process.env.REACT_APP_GRAPHQL_ENDPOINT)
                    url.searchParams.append('query', operation.query)
                    if (operation.variables) {
                        url.searchParams.append(
                            'variables',
                            JSON.stringify(operation.variables)
                        )
                    }
                    return {
                        subscribe(sink) {
                            const eventsource = new EventSource(url.toString(), {
                                withCredentials: true // This is required for cookies
                            })
                            eventsource.onmessage = (event) => {
                                const data = JSON.parse(event.data)
                                sink.next(data)
                                if (eventsource.readyState === 2) {
                                    sink.complete()
                                }
                            }
                            eventsource.onerror = (error) => {
                                sink.error(error)
                            }
                            eventsource.addEventListener('complete', () => {
                                eventsource.close() // If operation ends, close the connection and prevent the client from reconnecting
                            })
                            return {
                                unsubscribe: () => eventsource.close()
                            }
                        }
                    }
                }
            })
        ],
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <AuthProvider>
                    <Provider value={client}>
                        <BookingProvider>
                            <SupabaseProvider>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                            </SupabaseProvider>
                        </BookingProvider>
                    </Provider>
                </AuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
