
import OnboardCardWrapper from "./OnboardCardWrapper";
import { Grid, Typography, Divider, Stack } from "@mui/material";
import OnboardWrapper from "./OnboardingWrapper";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import OnboardForm from "./OnboardForm";


const Onboarding = () => {
    const theme = useTheme();

    return (
        <OnboardWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <OnboardCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant="h2"
                                                    >
                                                        Let's Splash right in!
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="16px"
                                                        textAlign={'center'}
                                                    >
                                                        To get started please fill in your information below.
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <OnboardForm />
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </OnboardCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </OnboardWrapper>
    );
};

export default Onboarding;